.index-change-button {
    background-color: #F18530;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
  }
  @-webkit-keyframes glowing {
    0% { background-color: #F18530; -webkit-box-shadow: 0 0 3px #F18530; }
    50% { background-color: #F18530; -webkit-box-shadow: 0 0 30px #F18530; }
    100% { background-color: #F18530; -webkit-box-shadow: 0 0 3px #F18530; }
  }
  
  @-moz-keyframes glowing {
    0% { background-color: #F18530; -moz-box-shadow: 0 0 3px #F18530; }
    50% { background-color: #F18530; -moz-box-shadow: 0 0 30px #F18530; }
    100% { background-color: #F18530; -moz-box-shadow: 0 0 3px #F18530; }
  }
  
  @-o-keyframes glowing {
    0% { background-color: #F18530; box-shadow: 0 0 3px #F18530; }
    50% { background-color: #F18530; box-shadow: 0 0 30px #F18530; }
    100% { background-color: #F18530; box-shadow: 0 0 3px #F18530; }
  }
  
  @keyframes glowing {
    0% { background-color: #F18530; box-shadow: 0 0 3px #F18530; }
    50% { background-color: #F18530; box-shadow: 0 0 30px #F18530; }
    100% { background-color: #F18530; box-shadow: 0 0 3px #F18530; }
  }